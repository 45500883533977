<template>
  <b-modal v-model="modalQuickActions" centered
    :title=" typeQuick ?  $t('components.dashboard.product.quickActions.'+ typeQuick + 'Title') : '' "
    content-class="card form-rounded-inputs card-ecart" header-class="card-header" @hidden="fnRestModalForms()"
    cancel-variant="danger" size="md" hide-header-close>
    <div v-show=" typeQuick == 'category' ">
      <p v-text=" $t('components.dashboard.product.quickActions.categoryMessage') "> </p>
      <v-observer tag="form" @submit.prevent="fnUpdateQuick()" ref="categoryForm">
        <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.category') ">
          <div class=" form-group ">
            <div class=" " :class=" errors.length > 0 ? ' is-invalid': '' ">
              <v-tree class=" " v-model="category.id" :load-options="fnApiGetCategories" :async="true"
                :placeholder="$t('general.form.placeholderCategory')" :defaultOptions="categoriesList"
                :loadingText="$t('tables.loading') + '...' " :noResultsText="$t('messages.noResults') + '...' "
                :normalizer="fnNormalizer"> </v-tree>
            </div>
            <div class="invalid-feedback" v-if="errors.length > 0">
              {{ errors[0] }}
            </div>
          </div>
        </v-validation>
        <input type="submit" hidden>
      </v-observer>
    </div>

    <div v-show=" typeQuick == 'tags' ">
      <p v-text=" $t('components.dashboard.product.quickActions.tagsMessage') "> </p>
      <v-observer tag="form" @submit.prevent="fnUpdateQuick()" ref="tagsForm">
        <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.tags') ">
          <div class="input-group ">
            <b-form-tags v-model="tags" class="" :placeholder="$t('general.form.addTags') " tag-variant="primary"
              separator="," :class=" { 'is-invalid':errors.length > 0 } ">
            </b-form-tags>
            <div class="input-group-append">
              <span class="input-group-text"> <i class="fa fa-tags"></i> </span>
            </div>
            <div class="invalid-feedback " v-if="errors.length > 0">
              {{ errors[0] }}
            </div>
          </div>
        </v-validation>
        <input type="submit" hidden>
      </v-observer>
    </div>

    <div v-show=" typeQuick == 'dimensions' ">
      <p v-text=" $t('components.dashboard.product.quickActions.dimensionsMessage') "> </p>
      <v-observer tag="form" @submit.prevent="fnUpdateQuick()" ref="dimensionsForm">
        <div class="row">
          <div class="col-12 ">
            <div class="row">

              <div class="col-6 col-sm-6 ">
                <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.width') ">
                  <div class="form-group">
                    <label for="">
                      <i class="fa fa-arrows-alt-h mr-1 "></i>
                      <span v-text="$t('general.form.width')"></span>
                    </label>
                    <div class="input-group mb-3">
                      <input type="number" min="1" step="1" class="form-control" v-model=" dimensions.width "
                        :class=" { 'is-invalid':errors.length > 0 } ">
                      <div class="input-group-append">
                        <span class="input-group-text rounded-right " id="unitsWidth"> <strong
                            v-text=" CONST.UNITS.LENGTH "> </strong> </span>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </div>
                </v-validation>
              </div>

              <div class="col-6 col-sm-6 ">

                <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.height') ">
                  <div class="form-group">
                    <label for="">
                      <i class="fa fa-arrows-alt-v mr-1 "></i>
                      <span v-text="$t('general.form.height')"></span>
                    </label>
                    <div class="input-group">
                      <input type="number" min="1" step="1" class="form-control" v-model=" dimensions.height  "
                        :class=" { 'is-invalid':errors.length > 0 } ">
                      <div class="input-group-append">
                        <span class="input-group-text rounded-right" id="unitsHeight"> <strong
                            v-text=" CONST.UNITS.LENGTH "> </strong> </span>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </div>
                </v-validation>
              </div>
              <div class="col-6 col-sm-6 ">

                <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.length') ">
                  <div class="form-group">
                    <label for="">
                      <i class=" fa fa-ruler-vertical mr-1"></i>
                      <span v-text=" $t('general.form.length') "></span>
                    </label>
                    <div class="input-group">
                      <input type="number" min="1" step="1" class="form-control" v-model=" dimensions.length  "
                        :class=" { 'is-invalid':errors.length > 0 } ">
                      <div class="input-group-append">
                        <span class="input-group-text rounded-right" id="unitsDepth"> <strong
                            v-text=" CONST.UNITS.LENGTH "> </strong> </span>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </div>
                </v-validation>
              </div>
              <div class="col-6 col-sm-6 ">
                <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.weight') ">
                  <div class="form-group">
                    <label for="">
                      <i class="fa fa-weight-hanging mr-1"></i>
                      <span v-text=" $t('general.form.weight') "></span>
                    </label>
                    <div class="input-group">
                      <input type="number" min="0.1" step="0.1" class="form-control" v-model=" dimensions.weight  "
                        :class=" { 'is-invalid':errors.length > 0 } ">
                      <div class="input-group-append">
                        <span class="input-group-text rounded-right" id="unitsWeight"> <strong
                            v-text=" CONST.UNITS.WEIGHT "> </strong> </span>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </div>
                </v-validation>
              </div>

            </div>
          </div>
          <input type="submit" hidden>
        </div>
      </v-observer>
    </div>

    <div v-show=" typeQuick == 'sellOutOfStock' ">
      <p v-text=" $t('components.dashboard.product.quickActions.sellOutOfStockMessage') "> </p>
    </div>

    <div v-show=" typeQuick == 'prepTime' ">
      <p v-text="$t('dashboard.product.preparationMessage')"> </p>
      <v-observer tag="form" class=" animated fadeIn" ref="formPreparation" @submit.prevent="fnUpdateQuick">

        <v-validation rules="required" v-slot="{ errors }" :name="$t('dashboard.product.preparationTitle')">
          <div class="form-group">
            <div class="input-group">
              <input type="number" min="0" step="1" class="form-control" v-model=" preparationTime  "
                :class=" { 'is-invalid':errors.length > 0 } ">
              <div class="input-group-append">
                <span class="input-group-text rounded-right f-w-500">
                  <i class=" fa fa-stopwatch "> </i>
                </span>
              </div>
              <div class="invalid-feedback" v-if="errors.length > 0">
                {{ errors[0] }}
              </div>
            </div>
          </div>
        </v-validation>

        <input type="submit" hidden>
      </v-observer>
    </div>

    <div v-show=" typeQuick == 'delete' ">
      <p v-text="$t('messages.deleteProducts')"> </p>
    </div>

    <div v-show=" typeQuick == 'deactivate' ">
      <p v-text="$t('messages.deactivateProducts')"> </p>
    </div>
    <div v-show=" typeQuick == 'activate' ">
      <p v-text="$t('messages.activateProducts')"> </p>
    </div>

    <template v-slot:modal-footer>
      <div class="d-flex jusify-content-end ">
        <b-button variant="none" class=" mr-2" @click=" fnRestModalForms() " v-text=" $t('general.button.close') ">
        </b-button>
        <b-button variant="success" class=" " :disabled="sendingData" @click=" fnUpdateQuick() ">
          <span v-text=" btnText " v-if=" !sendingData "></span>
          <i class="fa fa-spinner fa-spin " v-else></i>
        </b-button>
      </div>
    </template>
  </b-modal>

</template>

<script>
  export default {
    name: "ProductQuickActionsModal",
    props: {
      showQuickModal: {
        type: Boolean,
        default: false,
      },
      typeQuick: {
        type: String,
        required: false,
      },
      productArray: {
        type: Array,
        required: true,
      },
      productInfo: {
        type: Object,
        required: false,
      },
    },
    watch: {
      showQuickModal() {
        this.modalQuickActions = true;
      },
      productInfo() {
        if (this.productInfo != null && this.productInfo.category) {
          this.category.id = this.productInfo.category.id || null;
        } else {}
      }
    },
    data() {
      return ({
        sendingData: false,
        modalQuickActions: false,
        category: {
          id: null,
        },
        categoriesList: [],
        tags: [],
        dimensions: {
          width: null,
          height: null,
          length: null,
          weight: null,
        },
        preparationTime: null,
      })
    },
    computed: {
      btnText() {
        let text;
        switch (this.typeQuick) {
          case 'delete':
            text = this.$t('general.button.delete');
            break;
          case 'deactivate':
            text = this.$t('general.button.deactivate');
            break;
          case 'activate':
            text = this.$t('general.button.activate');
            break;
          default:
            text = this.$t('general.button.update');
            break;
        }
        return text;
      }
    },
    methods: {
      fnGetRootCategories() {
        let language = ''
        switch (this.$store.state.language) {
          case 'en':
            language = 'en-US';
            break;
          case 'es':
            language = 'es-ES';
            break;
          default:
            language = 'en-US';
            break;
        }
        axios.get(`categories/root?lang=${language}`).then((response) => {
          this.categoriesList = response.data;
        }).catch(error => {});
      },
      async fnUpdateQuick() {
        switch (this.typeQuick) {
          case 'category':
            this.fnApiUpdateCategories();
            break;
          case 'dimensions':
            this.fnApiUpdateDimensions();
            break;
          case 'sellOutOfStock':
            this.fnApiSellOutOfStock();
            break;
          case 'tags':
            this.fnApiUpdateTags();
            break;
          case 'prepTime':
            this.fnApiUpdatePreparationTime();
            break;
          case 'delete':
            this.fnApiBulkDeleteProducts();
            break;
          case 'deactivate':
            this.fnApiBulkDeactivateProducts();
            break;
          case 'activate':
            this.fnApiBulkActivateProducts();
            break;
        }
      },
      // Categories
      async fnApiUpdateCategories() {
        if (await this.$refs['categoryForm'].validate()) {
          this.sendingData = true;
          await axios.put('../products', {
            category: this.category,
            ids: this.productArray
          }).then((response) => {
            this.$emit('update-products', response.data);
            this.fnRestModalForms();
            this.$toasted.global.infoMessage(this.$t('toasted.info.productsCategoryUpdated'));
          }).catch(error => {});
          this.sendingData = false;
        }
      },
      // Categories
      async fnApiUpdateTags() {
        if (await this.$refs['tagsForm'].validate()) {
          this.sendingData = true;
          await axios.put('../products', {
            tags: this.tags,
            ids: this.productArray
          }).then((response) => {
            this.$emit('update-products', response.data);
            this.fnRestModalForms();
            this.$toasted.global.infoMessage(this.$t('toasted.info.productsTagsUpdated'));
          }).catch(error => {});
          this.sendingData = false;
        }
      },

      // Dimensions
      async fnApiUpdateDimensions() {
        if (await this.$refs['dimensionsForm'].validate()) {
          this.sendingData = true;
          await axios.put('../products', {
            dimensions: this.dimensions,
            ids: this.productArray
          }).then((response) => {
            this.$emit('update-products', this.dimensions);
            this.fnRestModalForms();
            this.$toasted.global.infoMessage(this.$t('toasted.info.productsDimenisonsUpdated'));
          }).catch(error => {});
          this.sendingData = false;
        }
      },
      // Sell out of stock
      async fnApiSellOutOfStock() {
        this.sendingData = true;
        await axios.put('../products', {
          sell_out_stock: true,
          ids: this.productArray
        }).then((response) => {
          this.$emit('update-products', response.data);
          this.fnRestModalForms();
          this.$toasted.global.infoMessage(this.$t('toasted.info.productSellOuOfStockUpdate'));
        }).catch(error => {});
        this.sendingData = false;
      },

      async fnApiUpdatePreparationTime() {

        if (await this.$refs['formPreparation'].validate()) {
          this.sendingData = true;
          await axios.put('../products', {
            preparation_time: this.preparationTime,
            ids: this.productArray
          }).then((response) => {
            this.$emit('update-products', response.data);
            this.fnRestModalForms();
            Vue.toasted.global.infoMessage(i18n.t('toasted.info.update'));
          }).catch(error => {});
          this.sendingData = false;
        }
      },

      // Delete products
      async fnApiBulkDeleteProducts() {
        this.sendingData = true;
        await axios.put('../products', {
          delete: true,
          ids: this.productArray
        }).then((response) => {
          this.$emit('update-products');
          this.fnRestModalForms();
          this.$toasted.global.infoMessage(this.$t('toasted.info.productsDeleted'));
        }).catch(error => {});
        this.sendingData = false;
      },

      // Deactivate products
      async fnApiBulkDeactivateProducts() {
        this.sendingData = true;
        await axios.put('../products', {
          active: false,
          ids: this.productArray
        }).then((response) => {
          this.$emit('update-products');
          this.fnRestModalForms();
          this.$toasted.global.infoMessage(this.$t('toasted.info.productsDeactivate'));
        }).catch(error => {});
        this.sendingData = false;
      },

      // Activate products
      async fnApiBulkActivateProducts() {
        this.sendingData = true;
        await axios.put('../products', {
          active: true,
          ids: this.productArray
        }).then((response) => {
          this.$emit('update-products');
          this.fnRestModalForms();
          this.$toasted.global.infoMessage(this.$t('toasted.info.productsActivate'));
        }).catch(error => {});
        this.sendingData = false;
      },

      fnRestModalForms() {
        this.modalQuickActions = false;
        this.category = {
          id: null,
        }
        this.tags = [];
        this.dimensions = {
          width: null,
          height: null,
          length: null,
          weight: null,
        };
        this.preparationTime = null;
        this.$emit('close-modal');
      },

    },
    beforeMount(){
      this.fnGetRootCategories();
    },
    mounted() {}
  }
</script>

<style>
</style>